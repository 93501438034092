import { CookiePopup, Footer, RedLoading, SEO } from 'components';
import { allSportsInfo } from 'const';
import { motion } from 'framer-motion';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { AlphaSizes } from 'modules/measurements';
import { AppState } from 'modules/redux-store';
import { Sports, SupportLevels } from 'modules/sports';
import { RecommendationText } from 'modules/sports/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { SupportRecommendations } from 'services';
import { AlphaSizesRecommendations } from 'services/AlphaSizesRecommendations';
import { buttons, results, titles, utils } from 'style';

const Results: React.FC = () => {
  const intl = useIntl();
  const { bandMeasure, ribMeasure, cupSize } = useSelector(
    (state: AppState) => state.measurements,
  );
  const inStoreExperience = useSelector(
    (state: AppState) => state.inStoreExperience.inStoreExperienceActive,
  );
  const sports = useSelector((state: AppState) => state.sports.sports);

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'resultsPageSeo' })}
        url="https://find-your-fit.eu/en/results"
        urlEN="https://find-your-fit.eu/en/results"
        urlDE="https://find-your-fit.eu/de/results"
        urlIT="https://find-your-fit.eu/it/results"
        urlNL="https://find-your-fit.eu/nl/results"
        urlFR="https://find-your-fit.eu/fr/results"
      />
      <RedLoading />
      <div css={utils.wrapper}>
        <section css={[results.root]}>
          <motion.h1
            css={[results.title, titles.primary]}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          >
            <FormattedMessage id="resultsTitlePartOne" /> <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="resultsTitlePartTwo" />
            </span>
          </motion.h1>

          <div css={results.items}>{renderRecommendationsPerSport()}</div>

          <footer css={results.footer}>{renderFooter()}</footer>
        </section>
      </div>
      <Footer />
    </PageTransition>
  );

  function renderFooter() {
    if (!inStoreExperience) {
      return (
        <div>
          <h2 css={[results.footerTitle, titles.primary]}>
            <FormattedMessage id="helpfulQuestion" />
            <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="shareCallout" />
            </span>
          </h2>
          <div css={results.footerAction}>
            <button
              css={[buttons.base, buttons.neutral]}
              onClick={handleStartAgain}
            >
              <FormattedMessage id="startAgainCta" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h2 css={[results.footerTitle, titles.primary]}>
          <FormattedMessage id="buyInStoreCalloutPartOne" />
          <br />
          <span css={utils.fontFamilySansAlt}>
            <FormattedMessage id="buyInStoreCalloutPartTwo" />
          </span>
        </h2>
        <div css={results.footerAction}>
          <button
            css={[buttons.base, buttons.neutral]}
            onClick={handleStartAgain}
          >
            <FormattedMessage id="startAgainCta" />
          </button>
        </div>
      </div>
    );
  }

  function handleStartAgain() {
    if (inStoreExperience) {
      navigate('/measure-up');
    } else {
      navigate('/');
    }
  }

  function renderRecommendationsPerSport() {
    return sports.map((sport) => renderCard(sport));
  }

  function renderCard(sport: Sports) {
    const thisSport = allSportsInfo.find(
      (sportDetails) => sportDetails.id === sport,
    );

    if (!cupSize || !thisSport) {
      return;
    }

    const chosenMeasurement = ribMeasure || bandMeasure;

    if (!chosenMeasurement) {
      return;
    }

    const supportLevel: SupportLevels =
      SupportRecommendations[thisSport.impact][chosenMeasurement][cupSize];

    const alphaSize: AlphaSizes =
      AlphaSizesRecommendations[chosenMeasurement][cupSize];

    return (
      <div css={results.item}>
        <RecommendationText
          sport={thisSport}
          supportLevel={supportLevel}
          alphaSize={alphaSize}
        />
        <CookiePopup />
      </div>
    );
  }
};

export default Results;
