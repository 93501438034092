import { CupSizes } from 'modules/measurements/models';
import { SportsImpact } from 'modules/sports';
import { SupportLevels } from 'modules/sports/models/SupportLevels';

interface BodySize {
  [prop: number]: Record<CupSizes, SupportLevels>;
}

export const SupportRecommendations: Record<SportsImpact, BodySize> = {
  High: {
    63: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    64: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    65: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    66: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    67: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    68: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    69: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    70: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    71: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    72: {
      A: 'Medium',
      B: 'Medium',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    73: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    74: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    75: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    76: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    77: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    78: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    79: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    80: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    81: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    82: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    83: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    84: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    85: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    86: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    87: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    88: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    89: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    90: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    91: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
    92: {
      A: 'Medium or High',
      B: 'Medium or High',
      C: 'High',
      D: 'High',
      E: 'High',
    },
  },
  Medium: {
    63: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    64: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    65: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    66: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    67: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    68: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    69: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    70: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    71: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    72: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    73: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    74: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    75: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    76: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    77: {
      A: 'Medium or Light',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    78: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    79: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    80: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    81: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    82: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium',
      D: 'High',
      E: 'High',
    },
    83: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    84: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    85: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    86: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    87: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    88: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    89: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    90: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    91: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
    92: {
      A: 'Medium',
      B: 'Medium',
      C: 'Medium or High',
      D: 'High',
      E: 'High',
    },
  },
  Light: {
    63: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    64: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    65: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    66: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    67: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    68: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    69: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    70: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    71: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    72: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    73: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    74: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    75: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    76: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    77: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    78: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    79: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    80: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    81: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    82: {
      A: 'Light',
      B: 'Light',
      C: 'Light',
      D: 'Full High',
      E: 'Full High',
    },
    83: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    84: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    85: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    86: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    87: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    88: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    89: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    90: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    91: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
    92: {
      A: 'Light',
      B: 'Light',
      C: 'Light or Medium',
      D: 'Full High',
      E: 'Full High',
    },
  },
};
