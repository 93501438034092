import { AlphaSizes, CupSizes } from 'modules/measurements/models';

interface BodySize {
  [prop: number]: Record<CupSizes, AlphaSizes>;
}

export const AlphaSizesRecommendations: BodySize = {
  63: {
    A: 'XS',
    B: 'XS',
    C: 'XS',
    D: 'XS',
    E: 'XS',
  },
  64: {
    A: 'XS',
    B: 'XS',
    C: 'XS',
    D: 'XS',
    E: 'XS',
  },
  65: {
    A: 'XS',
    B: 'XS',
    C: 'XS',
    D: 'XS',
    E: 'XS',
  },
  66: {
    A: 'XS',
    B: 'XS',
    C: 'XS',
    D: 'XS',
    E: 'XS',
  },
  67: {
    A: 'XS',
    B: 'XS',
    C: 'XS',
    D: 'XS',
    E: 'XS',
  },
  68: {
    A: 'XS',
    B: 'XS',
    C: 'S',
    D: 'S',
    E: 'S',
  },
  69: {
    A: 'XS',
    B: 'XS',
    C: 'S',
    D: 'S',
    E: 'S',
  },
  70: {
    A: 'XS',
    B: 'XS',
    C: 'S',
    D: 'S',
    E: 'S',
  },
  71: {
    A: 'XS',
    B: 'XS',
    C: 'S',
    D: 'S',
    E: 'S',
  },
  72: {
    A: 'XS',
    B: 'XS',
    C: 'S',
    D: 'S',
    E: 'S',
  },
  73: {
    A: 'S',
    B: 'S',
    C: 'S',
    D: 'M',
    E: 'M',
  },
  74: {
    A: 'S',
    B: 'S',
    C: 'S',
    D: 'M',
    E: 'M',
  },
  75: {
    A: 'S',
    B: 'S',
    C: 'S',
    D: 'M',
    E: 'M',
  },
  76: {
    A: 'S',
    B: 'S',
    C: 'S',
    D: 'M',
    E: 'M',
  },
  77: {
    A: 'S',
    B: 'S',
    C: 'S',
    D: 'M',
    E: 'M',
  },
  78: {
    A: 'M',
    B: 'M',
    C: 'M',
    D: 'L',
    E: 'L',
  },
  79: {
    A: 'M',
    B: 'M',
    C: 'M',
    D: 'L',
    E: 'L',
  },
  80: {
    A: 'M',
    B: 'M',
    C: 'M',
    D: 'L',
    E: 'L',
  },
  81: {
    A: 'M',
    B: 'M',
    C: 'M',
    D: 'L',
    E: 'L',
  },
  82: {
    A: 'M',
    B: 'M',
    C: 'M',
    D: 'L',
    E: 'L',
  },
  83: {
    A: 'L',
    B: 'L',
    C: 'L',
    D: 'XL',
    E: 'XL',
  },
  84: {
    A: 'L',
    B: 'L',
    C: 'L',
    D: 'XL',
    E: 'XL',
  },
  85: {
    A: 'L',
    B: 'L',
    C: 'L',
    D: 'XL',
    E: 'XL',
  },
  86: {
    A: 'L',
    B: 'L',
    C: 'L',
    D: 'XL',
    E: 'XL',
  },
  87: {
    A: 'L',
    B: 'L',
    C: 'L',
    D: 'XL',
    E: 'XL',
  },
  88: {
    A: 'XL',
    B: 'XL',
    C: 'XL',
    D: 'XXL',
    E: 'XXL',
  },
  89: {
    A: 'XL',
    B: 'XL',
    C: 'XL',
    D: 'XXL',
    E: 'XXL',
  },
  90: {
    A: 'XL',
    B: 'XL',
    C: 'XL',
    D: 'XXL',
    E: 'XXL',
  },
  91: {
    A: 'XL',
    B: 'XL',
    C: 'XL',
    D: 'XXL',
    E: 'XXL',
  },
  92: {
    A: 'XL',
    B: 'XL',
    C: 'XL',
    D: 'XXL',
    E: 'XXL',
  },
};
